import React from "react";
import { useSelector } from "react-redux";

const FreePlan = () => {
  const pricing = useSelector((state) => state.dataText.dataJson.home.pricing);

  return (
    <div className="container">
      <div className="pricing-center">
        <div className="pricing-item">
          <div className="body-box">
            <div className="pricing-item-top">
              <h4 className="pricing-item-header">Free</h4>
            </div>
            <div className="pricing-item-advantage">
              <div className="advantage-1">
                {pricing.freePlan.advantage1?.map((el, inx) => (
                  <div className="text-advantage" key={inx + el.id}>
                    <h5
                      className={`advantage-header advantage-header-${inx + 1}`}
                    >
                      {el.header}
                    </h5>
                    <span className="advantage-span">{el.text}</span>
                  </div>
                ))}
              </div>
              <div className="advantage-2">
                {pricing.freePlan.advantage2?.map((el, inx) => (
                  <div className="text-advantage" key={inx + el.id}>
                    <div key={inx + el.id}>
                      {el.statues === "true" ? (
                        <i className="fa-solid fa-check"></i>
                      ) : el.statues === "truePro" ? (
                        <i className="fa-solid fa-circle-check"></i>
                      ) : el.statues === "false" ? (
                        <i className="fa-solid fa-xmark"></i>
                      ) : (
                        ""
                      )}

                      <h5
                        className={`advantage-header advantage-header-${
                          inx + 1
                        }`}
                      >
                        {el.header}
                      </h5>
                      <span className="advantage-span">{el.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* {pricing.freePlan.priceDefault && (
                    <div className="pricing-item-price">
                      <span className="dolar">{pricing.coins}</span>
                      <span className="price-span">
                        {<del>{pricing.freePlan.priceDefault}</del>}
                        {<ins>{pricing.freePlan.priceAfterDiscount}</ins>}
                      </span>
                      <span className="time">{pricing.time}</span>
                    </div>
                  )} */}
          </div>
          <div className="free-plan">
            {
              <a
                href="https://dash.omegastream.net"
                className="pricing-item-btn"
                target="_blank"
                rel="noreferrer"
              >
                {pricing.btnFree}
              </a>
            }
            <p>{pricing.freePlan.freePlanSubtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreePlan;
