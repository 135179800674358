import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

function Landing({ landing, landingImgs, lang }) {
  // const [landingNewHeader, setLandingNewHeader] = useState("");
  // useEffect(() => {
  //   let arrHeader = landing.header.split("");
  //   let headerAsClone = [];
  //   let num = 0;
  //   const numSet = setInterval(() => {
  //     if (num < arrHeader.length) {
  //       headerAsClone.push(arrHeader[num]);
  //       setLandingNewHeader(headerAsClone.join(""));
  //       num++;
  //     }
  //   }, 15);
  //   return () => {
  //     clearInterval(numSet);
  //   };
  // }, [landing]);

  return (
    <section className="homeLanding">
      <div className="container-wrapped">
        <div className="container landing-box">
          <h1 className="landing-header">{landing.header}</h1>
          <div className="landing-title">
            <span>
              {landing.mediumHeader}
            </span>
            <span>
              {landing.smallHeader}
            </span>
            <div className="landing-btns">
              <a 
                className="btn-start" 
                href="#pricing"
                rel="noreferrer"
              >
                {landing.btnStart}
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="hand-img one">
            <img src={landingImgs.landingBgImg} alt="" />
          </div>
          <div className="hand-img two">
            <img src={landingImgs.landingBgImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    landing: state.dataText.dataJson.home.landing,
    landingImgs: state.allImages.pagesImgs.homeImgs.landingImgs,
    lang: state.langDir.lang,
  };
};

export default connect(mapStateToProps)(Landing);
