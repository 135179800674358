import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  navbarPaths: {
    home: "/home",
    pricing: "/pricing",
    contactUs: "/contactus",
  },
  footerPaths: {
    privacyPolicy: "/privacy",
    termsOfService: "/terms-of-service",
    gdpr: "/gdpr",
    acceptableUse: "/acceptable-use",
    pricing: "/pricing",
    contactUs: "/contactus",
    home: "/home",
    compareToVimeo: "/omega-vs-vimeo",
    abuse: "/abuse",
  },
};

const allPathsSlice = createSlice({
  name: "allPaths",
  initialState,
  reducers: {},
});

export const { allPaths } = allPathsSlice.actions;

export default allPathsSlice.reducer;

// aboutUs: "/aboutus",
