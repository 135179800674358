import React from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { changeData } from "../../../redux/features/dataText/dataTextSlice";
import { changeLang } from "../../../redux/features/changLang/changLangSlice";

function Footer({
  footer,
  footerImgs,
  footerPaths,
  onHandleLinksActiveClosed,
}) {
  const theDate = new Date();
  const year = theDate.getFullYear();

  const footerPahts = useSelector((state) => state.allPaths.footerPaths);

  return (
    <footer className="footer" onClick={onHandleLinksActiveClosed}>
      <div className="footer-container">
        <div className="footer-div-container">
          <div className="container-title">
            <div className="footer-title">
              <h1>{footer.footerTitle}</h1>
              <p>{footer.footerDesc}</p>
            </div>
          </div>
          <div className="container-links">
            <div className="links-footer">
              <ul className="list-ul">
                <li className="list-item">
                  <NavLink className="list-item" to={footerPahts.home}>
                    {footer.footerLinks.home}
                  </NavLink>
                </li>
                <li className="list-item">
                  <NavLink className="list-item" to={footerPahts.pricing}>
                    {footer.footerLinks.pricing}
                  </NavLink>
                </li>
                <li className="list-item">
                  <a className="list-item" href="https://docs.omegastream.net/">
                    {footer.footerLinks.developers}
                  </a>
                </li>
                <li className="list-item">
                  <NavLink
                    className="list-item"
                    to={footerPahts.compareToVimeo}
                  >
                    {footer.footerLinks.compareVimeo}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="container-links">
            <div className="links-footer">
              <ul className="list-ul">
                <li className="list-item">
                  <NavLink className="list-item" to={footerPahts.contactUs}>
                    {footer.footerLinks.scheduleDemo}
                  </NavLink>
                </li>
                <li className="list-item">
                  <NavLink className="list-item" to={footerPahts.privacyPolicy}>
                    {footer.footerLinks.privacy}
                  </NavLink>
                </li>
                <li className="list-item">
                  <NavLink
                    className="list-item"
                    to={footerPahts.termsOfService}
                  >
                    {footer.footerLinks.terms}
                  </NavLink>
                </li>
                <li className="list-item">
                  <NavLink className="list-item" to={footerPahts.gdpr}>
                    GDPR
                  </NavLink>
                </li>
                <li className="list-item">
                  <NavLink className="list-item" to={footerPahts.acceptableUse}>
                    Acceptable Use
                  </NavLink>
                </li>
                <li className="list-item">
                  <NavLink className="list-item" to={footerPahts.abuse}>
                    {footer.footerLinks.abuse}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="container-bottom">
        <div className="copy-right-footer">
          {"© " + year + " "}
          {footer.copyRight}
          <p>London, United Kingdom</p>
          <p>contact@omegastream.net</p>
        </div>
        <div className="icons">
          <a href="https://twitter.com/omegastreamltd" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-twitter"></i>
          </a>
            <a href="https://www.linkedin.com/company/omegastream/" target="_blank" rel="noreferrer">
            <i class="fa-brands fa-linkedin"></i>
          </a>
          {/* <a href="https://www.facebook.com/profile.php?id=100092366617677" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="https://github.com/AhmedAbouelkher?tab=repositories&type=source" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-github"></i>
          </a> */}
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = (state) => {
  return {
    // lang: state.langDir.lang,
    // dir: state.langDir.dir,
    // navBar: state.dataText.dataJson.navBar,
    footer: state.dataText.dataJson.footer,
    footerImgs: state.allImages.footerImgs,
    footerPaths: state.allPaths.footerPaths,
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     changeLang: (language, direction) =>
//       dispatch(changeLang({ payloadLang: language, payloadDir: direction })),
//     changeData: (language) => dispatch(changeData(language)),
//   };
// };
export default connect(mapStateToProps)(Footer);
