import React from "react";
import PricingLists from "./blocks/PricingLists";
import { connect } from "react-redux";
import FreePlan from "./blocks/units/FreePlan";

function PricingSection({ pricing }) {
  return (
    <section className="pricing">
      <div className="container">
        <div className={`pricing-top`}>
          <h2 className="pricing-header">
            <a href="#pricing">{pricing.header}</a>
          </h2>
          <p className="pricing-paragraph" id="pricing">
            {pricing.paragraph}
          </p>
        </div>

        <FreePlan />

        <div className="pricing">
          <PricingLists />
        </div>
      <div className="container">
        {pricing.notes.map((el, inx) => (
          <span className="note" key={inx}>
            {el}
          </span>
        ))}
      </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    pricing: state.dataText.dataJson.home.pricing,
  };
};

export default connect(mapStateToProps)(PricingSection);
