import React from "react";
import { Link } from "react-router-dom";

function PricingItem({
  className,
  header,
  advantage,
  isActive,
  time,
  btnChoose,
  btnContact,
  priceDefault,
  priceAfterDiscount,
  coins,
}) {
  // const calcDiscount = parseInt(
  //   ((priceDefault - priceAfterDiscount) / priceDefault) * 100
  // );
  return (
    <>
      <div className={`box`}>
        <div
          className={`pricing-item ${className} ${
            isActive === "true" && "active"
          }`}
        >
          <div className="body-box">
            <div className="pricing-item-top">
              <h4 className="pricing-item-header">{header}</h4>
            </div>
            <div className="pricing-item-advantage">
              {advantage.map((el, inx) => advantageItem(inx, el))}
            </div>
            {priceDefault && (
              <div className="pricing-item-price">
                <span className="dolar">{coins}</span>
                <span className="price-span">
                  {<del>{priceDefault}</del>}
                  {<ins>{priceAfterDiscount}</ins>}
                </span>
                <span className="time">{time}</span>
              </div>
            )}
          </div>
          {header === "Enterprise" ? (
            <Link to="/contactus" className="pricing-item-btn">
              {btnContact}
            </Link>
          ) : (
            <a
              href="https://dash.omegastream.net"
              className="pricing-item-btn"
              target="_blank"
              rel="noreferrer"
            >
              {btnChoose}
            </a>
          )}
        </div>
      </div>
    </>
  );
}
export default PricingItem;


function advantageItem(inx, el) {
  let icon = ("")
  switch (el.statues) {
    case "true":
      icon = (<i className="fa-solid fa-check"></i>)
      break;
    case "truePro":
     icon = (<i className="fa-solid fa-circle-check"></i>)
     break
    case "false":
      icon = (<i className="fa-solid fa-xmark"></i>)
      break
    default:
      break
  }

  let valueText = <span className="advantage-span">{el.text}</span>
  if(el.text === "coming_soon") {
    valueText = <div className="coming-soon">Coming Soon</div>
  } 
  

  return <div key={inx + el.id}>
    {icon}
    <div className="text-advantage">
      {/* Key */}
      <h5
        className={`advantage-header advantage-header-${inx + 1}`}
      >
        {el.header}
      </h5>
      {/* Value */}
      {valueText}
    </div>
  </div>;
}
// {
//   isActive === "true" && (
//     <span className="discount">
//       {calcDiscount && (
//         <span data-content={discount + " "}>
//           {discount}
//           {calcDiscount}
//         </span>
//       )}
//     </span>
//   );
// }
